import React from "react"
import Layout from "../../../components/layout"
import { Link, useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"
import { Helmet } from "react-helmet"
import productsPageStyles from "../../../styles/productsPage.module.scss"
import data from "../../../data/fps551"
import NACAB from "../../resources/NA_CAB-Pressure-Cab-Protection-Levels.pdf"
import CRFR from "../../resources/CAF 2 Recommended Flow Rate Test Procedure  R20134.pdf"
import FII from "../../resources/FPS Installation Instructions.pdf"
import PSFTP from "../../resources/CAF 3 Particulate System  Filter Test Procedure R20134.pdf"
import VTC from "../../resources/CAF 6 Vapor Test Procedure R20204.pdf"
import FPSBI from "../../resources/2021/FPS Basic Installation.pdf"

export default function () {
  const { fps551 } = useStaticQuery(
    graphql`
      query fps551 {
        fps551: file(relativePath: { eq: "products-pressurizers.png" }) {
          childImageSharp {
            fluid(maxWidth: 750) {
              ...GatsbyImageSharpFluid_tracedSVG
            }
            fixed(width: 650) {
              ...GatsbyImageSharpFixed_tracedSVG
            }
          }
        }
      }
    `
  )
  return (
    <Layout>
      <Helmet>
        <meta charSet="utf-8" />
        <title>{`${data.title} | Clean Air Filter`}</title>
      </Helmet>
      <section>
        <h1>
          <span className="tail">
            <span className="head">{data.content.section1.h1.head}</span>{" "}
            {data.content.section1.h1.tail}
          </span>
        </h1>
        <div className={productsPageStyles.floatRight}>
          <Img fluid={fps551.childImageSharp.fluid} />
        </div>
        <p>{data.content.section1.p1}</p>
      </section>
      <section>
        <article className="w-60">
          <h2>{data.content.section3.h2}</h2>
          <ul>
            <li
              dangerouslySetInnerHTML={{
                __html: data.content.section3.ul.li0,
              }}
            ></li>
            <li
              dangerouslySetInnerHTML={{
                __html: data.content.section3.ul.li1,
              }}
            ></li>
            <li
              dangerouslySetInnerHTML={{
                __html: data.content.section3.ul.li2,
              }}
            ></li>
            <li
              dangerouslySetInnerHTML={{
                __html: data.content.section3.ul.li3,
              }}
            ></li>
            <li
              dangerouslySetInnerHTML={{
                __html: data.content.section3.ul.li4,
              }}
            ></li>
            <li
              dangerouslySetInnerHTML={{
                __html: data.content.section3.ul.li5,
              }}
            ></li>
            <li
              dangerouslySetInnerHTML={{
                __html: data.content.section3.ul.li6,
              }}
            ></li>
            <li
              dangerouslySetInnerHTML={{
                __html: data.content.section3.ul.li7,
              }}
            ></li>
            <li
              dangerouslySetInnerHTML={{
                __html: data.content.section3.ul.li8,
              }}
            ></li>
            {/* <li>
                <p>
                  <Link
                    className="anchor-link"
                    to="/products/filtration-protection-systems/fps-filters"
                  >
                    {data.content.section3.ul.li8}
                  </Link>
                </p>
              </li> */}
            <li
              dangerouslySetInnerHTML={{
                __html: data.content.section3.ul.li9,
              }}
            ></li>
          </ul>
        </article>
      </section>
      <section>
        <article className="w-60">
          <h2>{data.content.section2.h2}</h2>
          <ul>
            <li
              dangerouslySetInnerHTML={{
                __html: data.content.section2.ul.li0,
              }}
            ></li>
            <li
              dangerouslySetInnerHTML={{
                __html: data.content.section2.ul.li1,
              }}
            ></li>
            <li
              dangerouslySetInnerHTML={{
                __html: data.content.section2.ul.li2,
              }}
            ></li>
            <li
              dangerouslySetInnerHTML={{
                __html: data.content.section2.ul.li3,
              }}
            ></li>
            <li
              dangerouslySetInnerHTML={{
                __html: data.content.section2.ul.li4,
              }}
            ></li>
            <li
              dangerouslySetInnerHTML={{
                __html: data.content.section2.ul.li5,
              }}
            ></li>
            <li
              dangerouslySetInnerHTML={{
                __html: data.content.section2.ul.li6,
              }}
            ></li>
            <li
              dangerouslySetInnerHTML={{
                __html: data.content.section2.ul.li7,
              }}
            ></li>
            <li
              dangerouslySetInnerHTML={{
                __html: data.content.section2.ul.li8,
              }}
            ></li>
          </ul>
        </article>
      </section>
      <section>
        <article className="w-60">
          <h2>{data.content.section4.h2}</h2>
          <ul>
            <li
              dangerouslySetInnerHTML={{
                __html: data.content.section4.ul.li0,
              }}
            ></li>
            <li
              dangerouslySetInnerHTML={{
                __html: data.content.section4.ul.li1,
              }}
            ></li>
            <li
              dangerouslySetInnerHTML={{
                __html: data.content.section4.ul.li2,
              }}
            ></li>
            <li
              dangerouslySetInnerHTML={{
                __html: data.content.section4.ul.li3,
              }}
            ></li>
            <li
              dangerouslySetInnerHTML={{
                __html: data.content.section4.ul.li4,
              }}
            ></li>
            <li
              dangerouslySetInnerHTML={{
                __html: data.content.section4.ul.li5,
              }}
            ></li>
            <li
              dangerouslySetInnerHTML={{
                __html: data.content.section4.ul.li6,
              }}
            ></li>
          </ul>
        </article>
      </section>

      <hr></hr>

      <div
        className="w-60"
        dangerouslySetInnerHTML={{ __html: data.content.systemOperation }}
      ></div>

      <p>
        <a
          className="download-link"
          href={FPSBI}
          target="_blank"
          rel="noreferrer"
        >
          {data.content.a7}
        </a>
      </p>
      {/* <p>
        <a
          className="download-link"
          href={"/downloads/FPS Basic Installation.pptx"}
          target="_blank"
          rel="noreferrer"
        >
          {data.content.a8}
        </a>
      </p> */}
      <p>
        <a
          className="download-link"
          href={FII}
          target="_blank"
          rel="noreferrer"
        >
          {data.content.a1}
        </a>
      </p>

      <div
        className="w-60"
        dangerouslySetInnerHTML={{ __html: data.content.pressurization }}
      ></div>

      <p>
        <a
          className="download-link"
          href={CRFR}
          target="_blank"
          rel="noreferrer"
        >
          {data.content.a2}
        </a>
      </p>
      <p>
        <a
          className="download-link"
          href={NACAB}
          target="_blank"
          rel="noreferrer"
        >
          {data.content.a3}
        </a>
      </p>
      <p>
        <a
          className="download-link"
          href={PSFTP}
          target="_blank"
          rel="noreferrer"
        >
          {data.content.a4}
        </a>
      </p>
      <p>
        <a
          className="download-link"
          href={VTC}
          target="_blank"
          rel="noreferrer"
        >
          {data.content.a5}
        </a>
      </p>
      <p>
        <Link
          className="primary red"
          to="/products/filtration-protection-systems/fps-filters"
        >
          View FPS Filters
        </Link>
      </p>
    </Layout>
  )
}
